import { useState } from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonSearchbar, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import UnidadeService from '../../services/unidades';
import { groupBy } from 'lodash';

import './Unidades.css';
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useToast } from '../../contexts/toast';

const tipoUnidade: any = {
  1: 'Água',
  2: 'Efluente'
}

interface UnidadesProps extends RouteComponentProps<{
  tipo: string
}> { };

const Unidades: React.FC<UnidadesProps> = ({ match, history }) => {

  const [loading, setLoading] = useState(true);
  const [unidades, setUnidades] = useState([]);
  const [unidadesMulti, setUnidadesMulti] = useState([]);
  const [unidadesFilter, setUnidadesFilter] = useState([]);
  const [search, setSearch] = useState('');
  const { alert } = useToast();

  useEffect(() => {
    loadUnidades();
  }, [])

  useEffect(() => {
    setDataUnidades(unidades);
  }, [search, unidades])


  const setDataUnidades = async (data: any) => {
    let result = [];

    if (search != '') {
      result = data.filter((item: any) => {
        return item.unnome.indexOf(search.toLocaleUpperCase()) != -1;
      });
    } else {
      result = data;
    }

    setUnidadesFilter((groupBy(result, (unidade) => [unidade.emp, unidade.empid]) as any));
  }

  const loadUnidades = async () => {
    try {
      const data = await UnidadeService.getAll();
      console.log('carrega..');
      setUnidades(data.unidades);
      setUnidadesMulti(data.multiViews);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert((err as Error).message);
    }
  }

  const _handlerRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    try {
      loadUnidades();
      event.detail.complete();
    } catch (err) {
      event.detail.complete();
    }
  }

  const _handlerClick = (unidade: number) => {
    if (match.params.tipo == 'relogios') {
      history.push(`/relogios/${unidade}`);
    } else if (match.params.tipo == 'galeria') {
      history.push(`/galeria/${unidade}`);
    } else {
      history.push(`/graficos/${unidade}`);
    }
  }

  const _handlerClickMulti = (unidade: any) => {
    history.push(`/relogiosMulti/${unidade.id}`);
  }

  const getList = () => {
    return Object.entries(unidadesFilter)
      .map((empresa) => {
        const nomeCidade = empresa[0].split(',');
        const empId = Number(nomeCidade[1]);
        const tiposDados = groupBy(empresa[1], (item: any) => item.tipo);

        return Object.entries(tiposDados).map((unidade, index) => {
          const tipo = unidade[0];
          const data = unidade[1];

          return <>
            <IonItemGroup key={index}>
              <IonItemDivider>
                <IonLabel className="group-title">
                  {tipoUnidade[tipo]}
                </IonLabel>
                <IonLabel slot="end" className="group-unidade">
                  {nomeCidade[0]}
                </IonLabel>
              </IonItemDivider>
            </IonItemGroup>
            {
              data.sort((a:any, b:any) => {
                return a.unnome.localeCompare(b.unnome);
              }).map((unidade, indexData) => (
                <IonItem key={`${index}-${indexData}`} onClick={(e) => _handlerClick(unidade.unid)}>
                  <IonLabel>{unidade.unnome}</IonLabel>
                </IonItem>
              ))
            }
            {/* // Unidade Multi */}
            {Number(tipo) == 1 &&
              unidadesMulti
                .filter((unidade: any) => (unidade.empresa == empId))
                .map((unidade: any, indexData) => (
                  <IonItem key={`${index}-${indexData}`} onClick={(e) => _handlerClickMulti(unidade)}>
                    <IonLabel>{unidade.nome}</IonLabel>
                  </IonItem>
                ))
            }
          </>
        })
      })
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="customdark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            Unidades
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={_handlerRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonList>
          <IonListHeader>
            <IonSearchbar
              placeholder="Pesquisar"
              value={search}
              onIonChange={(e) => setSearch(e.detail.value!)}></IonSearchbar>
          </IonListHeader>
        </IonList>
        {loading ?
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <IonSpinner name="crescent" />
          </div>
          : getList()}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Unidades);

import Api, { ConfigApi } from './api';
import { getTokenReflesh, unsetTokens, setTokensUser } from "../utils/auth";

export default {
    getInfo: async () => {
        const config: ConfigApi = {
            isAuth: true
        };
        return Api.get('/auth/info', config);
    },
    login: async (data: {
        login: string,
        senha: string
    }) => {
        try {
            const { token_access, token_refresh, usuario } = await Api.post('/auth/login', data, {
                isAuth: true
            });

            setTokensUser({
                token_access,
                token_refresh,
                usuario
            });

            return usuario
        } catch (err) {
            throw err;
        }
    },
    refresh: (tokenRefresh: string) => {
        return Api.post('/auth/refresh', {
            tokenRefresh
        }, {
            isAuth: true
        });
    },
    logout: async () => {
        const config = {
            isAuth: true
        };
        try {
            const tokenRefresh = getTokenReflesh();

            const data = {
                token: tokenRefresh
            };

            await Api.post('/auth/logout', data, config);

            //Remove tokens de acesso.
            unsetTokens();
        } catch (err: any) {
            const response = err.response;
            if (response === undefined) {
                throw Error(err);
            }
            else if (err.response.status === 401) {
                unsetTokens()
            }
            else {
                //alertError(response.data);

                throw Error(response.data);
            }
        }
    }
}

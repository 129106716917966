import { IonSpinner } from "@ionic/react";

interface ButtonLoginProps { 
    icon: string;
    isLoading?: boolean;
}

const ButtonLogin: React.FC<ButtonLoginProps> = (props) => {

    return (
        <button type="submit" style={{
            width:'150px',
            height: '60px',
            backgroundImage: `url(${props.icon})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: 'transparent',
            margin:'auto',
            textAlign:'left'
        
        }}
        >
            {
                props.isLoading &&  <IonSpinner name="crescent" />
            }
        </button>
    )
}

export default ButtonLogin;
import { useEffect, useState } from "react";

export interface ReservatorioProps {
    value: Number,
    description?: string
}

const Reservatorio: React.FC<ReservatorioProps> = (props) => {
    const [Size, SetSize] = useState(0);

    useEffect(() => {
        const value = (props.value as number);
        const sizeTot = 100;
        if (value > 100) {
            SetSize(sizeTot);
        }else{
            SetSize(value);
        }       
    }, [props.value])

    return (
        <div>
            <div className="gauge-value">{props.value}%</div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={'100%'}
                viewBox="0 0 225 156.377"
            >
                <g id="reservatorio" transform="translate(-59.712 -88.42)">
                    <g id="Layer1000" transform="translate(60.232 30.805)">
                        <path
                            id="path295"
                            d="M23.022,84.774v11.8H48.707v-11.8Z"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.67"
                        />
                        <path
                            id="path297"
                            d="M23.022,87.762H48.707"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path299"
                            d="M23.022,91.057H48.707"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path301"
                            d="M23.022,94.276H48.707"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path303"
                            d="M48.939,84.779v11.8H74.624v-11.8Z"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.67"
                        />
                        <path
                            id="path305"
                            d="M48.939,87.767H74.624"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path307"
                            d="M48.939,91.062H74.624"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path309"
                            d="M48.939,94.281H74.624"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path311"
                            d="M74.839,84.779v11.8h25.685v-11.8Z"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.67"
                        />
                        <path
                            id="path313"
                            d="M74.839,87.767h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path315"
                            d="M74.839,91.062h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path317"
                            d="M74.839,94.281h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path319"
                            d="M100.743,84.728v11.8h25.685v-11.8Z"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.67"
                        />
                        <path
                            id="path321"
                            d="M100.743,87.716h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path323"
                            d="M100.743,91.011h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path325"
                            d="M100.743,94.229h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path327"
                            d="M126.66,84.733v11.8h25.685v-11.8Z"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.67"
                        />
                        <path
                            id="path329"
                            d="M126.66,87.721h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path331"
                            d="M126.66,91.016h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path333"
                            d="M126.66,94.234h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path335"
                            d="M152.56,84.733v11.8h25.685v-11.8Z"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.67"
                        />
                        <path
                            id="path337"
                            d="M152.56,87.721h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path339"
                            d="M152.56,91.016h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <path
                            id="path341"
                            d="M152.56,94.234h25.685"
                            fill="none"
                            stroke="#ccc"
                            strokeWidth="0.38"
                        />
                        <g id="Layer1001">
                            <path
                                id="path343"
                                d="M178.5,84.733V96.51h25.635V84.733Z"
                                fill="none"
                                stroke="#ccc"
                                strokeWidth="0.67"
                            />
                            <path
                                id="path345"
                                d="M178.5,87.715h25.635"
                                fill="none"
                                stroke="#ccc"
                                strokeWidth="0.38"
                            />
                            <path
                                id="path347"
                                d="M178.5,91h25.635"
                                fill="none"
                                stroke="#ccc"
                                strokeWidth="0.38"
                            />
                            <path
                                id="path349"
                                d="M178.5,94.216h25.635"
                                fill="none"
                                stroke="#ccc"
                                strokeWidth="0.38"
                            />
                        </g>
                    </g>
                    <path
                        id="path353"
                        d="M88.371,132.657V238.829H259.414V132.657Z"
                        fill="#dbdbdb"
                        stroke="#dbdbdb"
                        strokeWidth="10.713"
                        fillRule="evenodd"
                    />
                    <path
                        id="path355"
                        d="M82.808,152.51l182.261.3"
                        fill="#999"
                        stroke="#949494"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path357"
                        d="M82.808,165.755l182,.3"
                        fill="#999"
                        stroke="#949494"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path359"
                        d="M82.774,180.5H264.947"
                        fill="#999"
                        stroke="#949494"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path361"
                        d="M82.817,194.05l182.252.6"
                        fill="#999"
                        stroke="#949494"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path363"
                        d="M82.825,207.6l182.16.452"
                        fill="#999"
                        stroke="#949494"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path365"
                        d="M82.819,221.894l181.958.6"
                        fill="#999"
                        stroke="#949494"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path367"
                        d="M83.493,235.29l180.437.451"
                        fill="#999"
                        stroke="#949494"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path369"
                        d="M88.32,132.622V238.793H259.362V132.622Z"
                        fill="rgba(148,148,148,0)"
                        stroke="#949494"
                        strokeWidth="10.713"
                        fillRule="evenodd"
                    />
                    <rect
                        width={162} height={Size} transform={'rotate(180,127,117)'} fill="#54aeed">
                        <animate
                            attributeName="height"
                            from="0"
                            to={Size}
                            dur="3s"
                            fill="freeze" />
                    </rect>
                    <path
                        id="path373"
                        d="M59.981,231.77V244.8H284.712V231.77Z"
                        fill="gray"
                        fillRule="evenodd"
                    />
                    <path
                        id="path375"
                        d="M74.436,156.071a47.336,47.336,0,0,0-2.7,10.053c-4.313,32.351,36.281,61.972,70.793,65.458"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.335"
                    />
                    <path
                        id="path377"
                        d="M82.641,148l-5.422.164"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path379"
                        d="M76.254,150.859l6.386-.394"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path381"
                        d="M82.807,153.822l-8.006.265"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path383"
                        d="M82.722,156.708l-8.562.169"
                        fill="#ccc"
                        stroke="#ccc"
                        strokeWidth="0.38"
                        fillRule="evenodd"
                    />
                    <path
                        id="path385"
                        d="M82.661,159.94l-9.72.233"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path387"
                        d="M82.792,162.739l-10.573.5"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path389"
                        d="M82.806,165.6l-11.066.52"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path391"
                        d="M82.942,168.314l-11.438.474"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path393"
                        d="M82.773,171.587l-11.341.426"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path395"
                        d="M82.872,174.59l-11.3.533"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path397"
                        d="M83.5,177.715l-11.315.445"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path399"
                        d="M84.122,181l-11.217.529"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path401"
                        d="M85.183,184.19l-11.207.586"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path403"
                        d="M86.621,187.068l-11.39.6"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path405"
                        d="M88.518,190.148l-11.678.573"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path407"
                        d="M90.236,192.964l-11.751.568"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path409"
                        d="M92.278,195.807l-11.906.675"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path411"
                        d="M94.548,198.849l-11.768.779"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path413"
                        d="M97.628,202.555l-11.643.734"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path415"
                        d="M100.352,205.288l-11.837.631"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path417"
                        d="M103.453,208.232l-11.893.57"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path419"
                        d="M106.938,211.069l-12.111.466"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path421"
                        d="M110.668,213.681l-12.142.788"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path423"
                        d="M113.558,215.834l-11.306,1.111"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path425"
                        d="M117.717,218.233l-11.594,1.041"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path427"
                        d="M121.994,220.4l-11.69,1.217"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path429"
                        d="M127.138,222.476l-12.629,1.112"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path431"
                        d="M157.527,231.77l-15.272-.19"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path433"
                        d="M82.692,140.678c-33.195,35.648,38.642,81.1,75.2,82.319"
                        fill="#ccc"
                        stroke="#ccc"
                        strokeWidth="0.67"
                        fillRule="evenodd"
                    />
                    <path
                        id="path435"
                        d="M157.532,223.183l-.005,8.837"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.67"
                    />
                    <path
                        id="path437"
                        d="M82.872,174.59c.7,9.684,6.5,18,12.786,25.7,8.567,10.5,21.427,18,27.527,20.571,10.494,4.427,19.312,7.3,34.341,10.911"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.335"
                    />
                    <path
                        id="path439"
                        d="M131.052,224.058l-12.17,1.464"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path441"
                        d="M135.476,225.6l-12.4,1.551"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path443"
                        d="M140.263,227.114l-12.17,1.464"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path445"
                        d="M144.422,228.361l-11.442,1.634"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path447"
                        d="M149.049,229.66l-10.3,1.464"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path
                        id="path449"
                        d="M152.81,230.582l-8.118,1.042"
                        fill="none"
                        stroke="#ccc"
                        strokeWidth="0.38"
                    />
                    <path id="path451" d="M59.712,88.42V244.6H284.687V88.42Z" fill="none" />
                </g>
            </svg>


            <div className="gauge-title">{props.description}</div>
        </div>
    );
};

export default Reservatorio;

import { useEffect, useState } from "react";


export interface TanqueProps {
    value: Number,
    description?: string
}

const Tanque: React.FC<TanqueProps> = (props) => {
    const [Size, SetSize] = useState(0);

    useEffect(() => {
        const value = (props.value as number);
        let size = 0;
        const sizeTot = 255;
        if(value > 100){
            size = sizeTot;
        }else{
            size =  (value * sizeTot) / 100;
        }
       
        SetSize(size);
    }, [props.value])


    return (
        <div>
            <div className="gauge-value">{props.value}%</div>
            <svg width={'100%'} viewBox="0 0 300 300">
                <image href={`${process.env.PUBLIC_URL}/assets/icon/tanque.png`} 
                    height={300} 
                    width={300} />
                <rect 
                    width={108} 
                    height={Size} 
                    transform={'rotate(180,101,142.5)'} 
                    fill="#54aeed">
                    
                    <animate 
                        attributeName="height" 
                        from="0" 
                        to={Size}
                        dur="3s" 
                        fill="freeze"/>
                </rect>
            </svg>
            <div className="gauge-title">{props.description}</div>
        </div>
    );
};

export default Tanque;

import { useField, FieldAttributes, Field } from "formik";
import './InputLogin.css';

type InputLoginProps = {
    label?: string;
    icon: string;
    name: string;
    className?: string;
  } & FieldAttributes<{}>;

const InputLogin: React.FC<InputLoginProps> = ({
    label,
    placeholder,
    type,
    className,
    ...props
  }) => {
    
    const [field, meta] = useField<{}>(props);
    return (
        <div>
            <div className="input-login">
                <img src={props.icon}/>
                <Field 
                    placeholder={placeholder} 
                    type={type} 
                    {...field}
                    style={{
                        backgroundColor:'transparent',
                        width: '100%'
                    }}/>
            </div>
            {meta.touched && meta.error && (
                <div className="input-login-error">{meta.error}</div>
            )}
        </div>
       
    )
}

export default InputLogin;
import Api, { ConfigApi } from './api';

export default {
    getAll: async (unidade:number) => {
        const config: ConfigApi = {
            isAuth: true
        };
        return Api.get(`/simpleView/${unidade}`, config);
    },
    getAllMulti: async (id:string) => {
        const config: ConfigApi = {
            isAuth: true
        };
        return Api.get(`/multiView/${id}`, config);
    },
}

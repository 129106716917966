import Api, { ConfigApi } from './api';

export default {
    getAll: async () => {
        const config: ConfigApi = {
            isAuth: true
        };
        return Api.get('/notificacoes', config);
    },
}

import { useState,useEffect } from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import NotificaoService from '../../services/notificacoes';
import { useToast } from '../../contexts/toast';
import moment from 'moment';

import './Notificacoes.css';

const Notificacoes: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [notificacoes, setNotificacoes] = useState([]);
  const {alert} = useToast();

  useEffect(() => {
    loadNotificacoes();
  }, [])

  const loadNotificacoes = async () => {
    try {
      const data = await NotificaoService.getAll();

      setNotificacoes(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert((err as Error).message);
    }
  }

  const _handlerRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    try {
      loadNotificacoes();
      event.detail.complete();
    } catch (err) {
      event.detail.complete();
    }
  }


  const getList = () => {
    return notificacoes.map((notificacao:any,index) => {
      return (
        <div key={index}>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel className="group-title">
                {notificacao.emissor}
              </IonLabel>
              <IonLabel slot="end" className="group-unidade">
                {notificacao.data ? moment(notificacao.data).format('DD/MM/YYYY HH:mm'): null}
              </IonLabel>
            </IonItemDivider>
          </IonItemGroup>
          <IonItem >
            <p className="group-content" dangerouslySetInnerHTML={{__html: notificacao.mensagem.replaceAll('\r\n','<br/>')}} />
          </IonItem>
        </div>
      )
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="customdark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            Notificações
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={_handlerRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {loading ?
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <IonSpinner name="crescent" />
          </div>
          : getList()}
      </IonContent>
    </IonPage>
  );
};

export default Notificacoes;

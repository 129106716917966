import axios,{AxiosResponse,AxiosRequestConfig,AxiosError} from 'axios';
import history from '../routes/history';

import { unsetTokens, setTokenAccess, getTokenAccess, getTokenReflesh } from '../utils/auth';

export const API_URL = `https://simos.saneamentobr.com.br/wsimos/api`;

export interface ConfigApi extends AxiosRequestConfig{
    baseURL?: string;
    headers?: any;
    isAuth?: boolean;
}

class Api {
    private config: ConfigApi;

    constructor() {
        this.config = {
            baseURL: API_URL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            validateStatus: function(status) {
                return status >= 200 && status < 300; // default
            },
            isAuth:true
        };

        // axios = axios.create(this.config);
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.defaults.baseURL = API_URL;
        axios.defaults.validateStatus = (status) => (status >= 200 && status < 300)

        //Middware capture erros and success
        axios.interceptors.response.use(this._handleSucess, this._handlerError.bind(this));
    }

    _handleSucess(response:AxiosResponse) {
        return response;
    }

    _handlerError(err:any) {
        if (err.response == undefined) {
            return Promise.reject(err);
        }

        //Caso for 401 atualizar token/
        if (err.config.headers.Authorization && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {

            const tokenRefresh = getTokenReflesh();

            if (!tokenRefresh) {
                console.warn('Token refresh nao encontrado..');
                this._logout();

                return Promise.reject(err);
            }

            //Atualiza token de acesso.
            return this._refreshToken(tokenRefresh)
                .then(({ token_access }) => {

                    // Grava novamente no localStore
                    setTokenAccess(token_access);

                    //Seta novo Bearer 
                    err.config.__isRetryRequest = true;
                    err.config.headers.Authorization = 'Bearer ' + getTokenAccess();

                    return axios(err.config);
                })
                .catch(err => {
                    if (err.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        if (err.response.status === 401) {
                            this._logout();
                        }
                    }

                    return Promise.reject(err);
                });
        }

        return Promise.reject(this._throwError(err));
    }

    // decora retorno com a mensagem de erro tratada.
    _throwError(error:AxiosError) {
        const response = error.response;
        const msgErro = [];

        if (response && (response.status == 400 || response.status == 403)) {
            // Verifica erro de validacao.
            const data = response.data;

            if (data.msg) {
                msgErro.push(data.msg);
            } else {
                for (let errField in data) {

                    const listError = data[errField];
                    for (let err in listError) {
                        msgErro.push(listError[err]);
                    }
                }
            }
        } else {
            msgErro.push(error.message);
        }

        return {
            ...error,
            message: msgErro.join('\n')
        }
    }

    _logout() {
        console.log('logout...');
        unsetTokens();
        window.location.reload();
    }

    _refreshToken(tokenRefresh:string) {
        return axios.post(`/auth/refresh`, { token: tokenRefresh })
            .then(({ data }) => Promise.resolve(data))
    }

    get(path:string, config:ConfigApi) {
        if (config && config.isAuth) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${getTokenAccess()}`
            }
        }
        //request
        return axios.get(path, config)
            .then(({ data }) => Promise.resolve(data))
    }
    post(path:string, data:any, config:ConfigApi) {
        if (config && config.isAuth) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${getTokenAccess()}`
            }
        }
        //request
        return axios.post(path, data, config)
            .then(({ data }) => Promise.resolve(data))
    }

    put(path:string, data:any, config:ConfigApi) {
        if (config && config.isAuth) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${getTokenAccess()}`
            }
        }
        // request 
        return axios.put(path, data, config)
            .then(({ data }) => Promise.resolve(data))
    }
    delete(path:string, config:ConfigApi) {
        if (config && config.isAuth) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${getTokenAccess()}`
            }
        }
        // request 
        return axios.delete(path, config)
            .then(({ data }) => Promise.resolve(data))
    }
}

export default new Api;
import { useEffect, useState } from "react";


export interface Amp250Props {
    value: Number,
    description?: string
}

const Amp250: React.FC<Amp250Props> = (props) => {
    const [anglePoiter, SetAnglePoiter] = useState(0);

    useEffect(() => {
        let angle = ((90 / 250) * (props.value as number));

        SetAnglePoiter(angle);
    }, [props.value])


    return (
        <div>
            <div className="gauge-value">{props.value}A</div>
            <svg width={'100%'} viewBox="0 0 300 300">
                <image href={`${process.env.PUBLIC_URL}/assets/icon/baseamper250.png`} height={300} width={300} />
                <image 
                    id="poly" 
                    href={`${process.env.PUBLIC_URL}/assets/icon/ponteiroamp.png`} 
                    height={300} 
                    width={300}
                    transform={`rotate(${anglePoiter},218,218)`}>

                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 218 218" 
                        to={`${anglePoiter} 218 218`} 
                        dur=".5s"
                        fill="freeze">
                        </animateTransform>
                </image>
            </svg>
            <div className="gauge-title">{props.description}</div>
        </div>
    );
};

export default Amp250;

import { useEffect, useState } from "react";


export interface Nano60Props {
    value: Number,
    description?:string
}

const Nano60: React.FC<Nano60Props> = (props) => {
    const [anglePoiter, SetAnglePoiter] = useState(0);

    useEffect(() => {
        let angle = ((280 / 60) * (props.value as number));

        SetAnglePoiter(angle);
    }, [props.value])


    return (
        <div>
            <div className="gauge-value">{props.value}mca</div>
            <svg width={'100%'} viewBox="0 0 300 300">
                <image href={`${process.env.PUBLIC_URL}/assets/icon/manometro60.png`} 
                    width={300}
                    height={300}/>
                <image
                    href={`${process.env.PUBLIC_URL}/assets/icon/ponteiromanometro.png`} 
                    width={300}
                    height={300}
                    transform={`rotate(${anglePoiter},150,150)`}>
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 150 150" 
                        to={`${anglePoiter} 150 150`} 
                        dur=".5s"
                        fill="freeze">
                        </animateTransform>  
                </image>
            </svg>
            <div className="gauge-title">{props.description}</div>
        </div>
    );
};

export default Nano60;

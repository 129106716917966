import React, { createContext, useContext, useEffect, useState } from 'react';
import { getTokenAccess, getUser, unsetTokens } from '../utils/auth';

interface AuthContextData {
    signed: boolean,
    user: any,
    login(userData: any): void;
    logout(): void;
}

const AuthContext = createContext({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<object | null>(null);

    useEffect(()=>{
        const storagedUser = getUser();
        const storageToken = getTokenAccess();
        
        if(storageToken && storagedUser){
            setUser(storagedUser);
        }
    },[])

    const login = (userData:any)=>{
        setUser(userData);
    }

    const logout = () =>{
        setUser(null);
        unsetTokens();
    }
    
    return (
      <AuthContext.Provider value={{ signed: Boolean(user), user, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
};

export function useAuth(){
    const context = useContext(AuthContext);
   
    return context;
}

export default AuthContext;
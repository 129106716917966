import React from 'react';
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonRouterOutlet, IonFooter, useIonRouter } from '@ionic/react';
import { useAuth } from '../../contexts/auth';
import { menuController } from '@ionic/core';

const MenuSide: React.FC = () => {
  const { logout } = useAuth();
  const { push } = useIonRouter();

  const _handlerLogout = (e: any) => {
    e.preventDefault();

    logout();
  }

  const navigateToPage = (url: string) => {
    push(url);
    menuController.toggle();
  };

  return (
    <>
      <IonMenu side="start" contentId="main">
        <IonHeader>
          <IonToolbar color="customdark">
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem button onClick={() => navigateToPage('/unidades/relogios')} routerDirection="none">Medidores</IonItem>
            <IonItem button onClick={() => navigateToPage('/unidades/graficos')} routerDirection="none">Gráficos</IonItem>
            <IonItem button onClick={() => navigateToPage('/unidades/galeria')} routerDirection="none">Galeria</IonItem>
            <IonItem button onClick={() => navigateToPage('/notificacoes')} routerDirection="none">Notificações</IonItem>
          </IonList>
        </IonContent>
        <IonFooter>
          <IonList>
            <IonItem onClick={_handlerLogout}>Sair</IonItem>
          </IonList>
        </IonFooter>
      </IonMenu>
    </>
  )
};

export default MenuSide;
import { useEffect, useState } from "react";


export interface Amp100Props {
    value: Number,
    description?: string
}

const Amp100: React.FC<Amp100Props> = (props) => {
    const [anglePoiter, SetAnglePoiter] = useState(0);

    useEffect(() => {
        let angle = ((90 / 100) * (props.value as number));

        SetAnglePoiter(angle);
    }, [props.value])

    return (
        <div>
            <div className="gauge-value">{props.value}A</div>
            <svg width={'100%'} viewBox="0 0 300 300">
                <image 
                    href={`${process.env.PUBLIC_URL}/assets/icon/baseamper100.png`} 
                    height={300} 
                    width={300} />
                <image 
                    href={`${process.env.PUBLIC_URL}/assets/icon/ponteiroamp.png`} 
                    height={300} 
                    width={300}
                    transform={`rotate(${anglePoiter},218,218)`}>
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 218 218" 
                        to={`${anglePoiter} 218 218`} 
                        dur=".5s"
                        fill="freeze">
                        </animateTransform>
                </image>
            </svg>

            <div className="gauge-title">{props.description}</div>
        </div>
    );
};

export default Amp100;

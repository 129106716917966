import Nano120 from "./nano120";
import Nano60 from "./nano60";

export interface NanoProps {
    value: Number,
    description?: string,
    maxValue: Number;
}

const Nano: React.FC<NanoProps> = (props) => {
    if (props.maxValue <= 60) {
        return (<Nano60 value={props.value} description={props.description} />);
    } else{
        return (<Nano120 value={props.value} description={props.description} />);
    } 
};

export default Nano;
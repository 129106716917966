import Api, { ConfigApi } from './api';

export default {
    getAll: async (unidade:number,offset?:number) => {
        const config: ConfigApi = {
            isAuth: true
        };
        return Api.get(`/detail/${unidade}?offset=${offset || 0}`, config);
    },
}

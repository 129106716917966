import Api, { ConfigApi } from './api';

export default {
    get: async (unidade:string) => {
        const config: ConfigApi = {
            isAuth: true
        };
        return Api.get(`/galeria/${unidade}`, config);
    },
}

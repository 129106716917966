import Amp100 from "./amp100";
import Amp250 from "./amp250";
import Amp50 from "./amp50";
import Amp500 from "./amp500";

export interface AmpProps {
    value: Number,
    description?: string,
    maxValue: Number;
}

const Amp: React.FC<AmpProps> = (props) => {
    if (props.maxValue <= 50) {
        return (<Amp50 value={props.value} description={props.description} />);
    } else if (props.maxValue <= 100) {
        return (<Amp100 value={props.value} description={props.description} />);
    } else if (props.maxValue <= 250) {
        return (<Amp250 value={props.value} description={props.description} />);
    } else {
        return (<Amp500 value={props.value} description={props.description} />);
    }
};

export default Amp;
import { useEffect, useState } from 'react';
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonListHeader, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import ChartsLine, { ChartsLineValue } from '../../components/chartsLine';
import { RefresherEventDetail } from '@ionic/core';
import { arrowBackCircle, arrowForwardCircle } from 'ionicons/icons';

import { RouteComponentProps, withRouter } from 'react-router';
import GraficosService from '../../services/graficos';

import './Graficos.css';
import { useToast } from '../../contexts/toast';
import moment from 'moment';
interface GraficosProps extends RouteComponentProps<{
  unidade: string
}> { };

interface Graphic {
  data: Array<any>,
  unidade_nome: string
}

const Graficos: React.FC<GraficosProps> = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const unidade: number = Number(match.params.unidade);
  const [graphicData, setGraphicData] = useState<Graphic>();
  const [offset, setOffset] = useState(0);
  const { alert } = useToast();

  useEffect(() => {
    loadGraficos();
  }, [offset])

  const loadGraficos = async () => {
    try {
      const data = await GraficosService.getAll(unidade, offset);

      setGraphicData(data);
      setLoading(false);
    } catch (err) {
      alert((err as Error).message);
    }
  }

  const _handlerRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    try {
      loadGraficos();
      event.detail.complete();
    } catch (err) {
      event.detail.complete();
    }
  }

  const _handlerNext = (event: any) => {
    event.preventDefault();
    if (offset <= 0) return;

    const backOffset = offset - 1;
    setOffset(backOffset);
  };

  const _handlerBack = (event: any) => {
    event.preventDefault();

    const backOffset = offset + 1;
    setOffset(backOffset);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="customdark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            Simos
          </IonTitle>
        </IonToolbar>
        <IonToolbar color="customdark">
          <div className="grafic-subtitle">
            <div className="title">{graphicData?.unidade_nome}</div>
            <IonButtons className="buttons">
              <IonButton size='large' onClick={_handlerBack}>
                <IonIcon icon={arrowBackCircle}></IonIcon>
              </IonButton>
              <IonButton size='large' onClick={_handlerNext}>
                <IonIcon icon={arrowForwardCircle}></IonIcon>
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={_handlerRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {loading ?
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <IonSpinner name="crescent" />
          </div>
          : <IonGrid>
            <IonRow>
              {
                graphicData?.data.map((graphic: any) => {
                  const data: Array<ChartsLineValue> = graphic.data
                    .filter((value: any, index: number) => (index % 3 == 0))
                    .map((values: any) => {
                      return {
                        name: moment(values.data).format('HH:mm'),
                        value: Number(values.valor).toFixed(1)
                      }
                    }).reverse();

                  return (
                    <IonCol sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="4">
                      <ChartsLine description={graphic.graph} data={data} colorLine={graphic.color} />
                    </IonCol>
                  )
                })
              }
            </IonRow>
          </IonGrid>
        }

      </IonContent>
    </IonPage>
  );
};

export default withRouter(Graficos);

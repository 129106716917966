
import MenuSide from '../components/menu/MenuSide';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import history from './history';
// Pages
import Login from '../pages/login/Login';
import Home from '../pages/home/Home';
import Unidades from '../pages/unidades/Unidades';
import Relogios from '../pages/relogios/Relogios';
import Graficos from '../pages/graficos/Graficos';
import AuthContext from '../contexts/auth';
import { Redirect, Route } from 'react-router';
import { useContext } from 'react';
import Notificacoes from '../pages/notificacoes/Notificacoes';
import RelogiosMulti from '../pages/relogiosMulti/RelogiosMulti';
import Galeria from '../pages/galeria/Galeria';

const PrivateRoutes = () => {
    return (
      <IonApp>
        <IonReactRouter history={history}>
          <MenuSide />
          <IonRouterOutlet id="main">
            <Route exact path="/">
              <Home />
            </Route>
  
            <Route exact path="/unidades/:tipo">
              <Unidades />
            </Route>
  
            <Route exact path="/relogios/:unidade">
              <Relogios />
            </Route>

            <Route exact path="/relogiosMulti/:id">
              <RelogiosMulti />
            </Route>
  
            <Route exact path="/graficos/:unidade">
              <Graficos />
            </Route>
            
            <Route exact path="/galeria/:unidade">
              <Galeria />
            </Route>

            <Route exact path="/notificacoes">
              <Notificacoes />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }
  
  const PublicRoutes = () => {
    return (
      <IonApp>
        <IonReactRouter history={history}>
          <IonRouterOutlet id="main">
            <Route exact path="/login">
              <Login />
            </Route>
  
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
  
            <Route path="*">
              <Login />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }
  

const Routes: React.FC = () => {
    const { signed } = useContext(AuthContext);
   
    return signed ? <PrivateRoutes /> : <PublicRoutes />;
};
   
export default Routes;
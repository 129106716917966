
import { IonToast } from '@ionic/react';
import React, { createContext, useContext, useState } from 'react';

interface ToastContextData {
    alert(messsage:string): void;
}

const ToastContext = createContext({} as ToastContextData);

export const ToastProvider:React.FC = ({ children })=>{
    const [message,setMessage] = useState('');

    const alert = (messageAlert:string) =>{
        setMessage(messageAlert);
    }

    return (
        <ToastContext.Provider value={{ alert }}>
            <IonToast
                isOpen={Boolean(message)}
                onDidDismiss={() => setMessage('')}
                message={message}
                duration={2000}
            />
          {children}
        </ToastContext.Provider>
      );
}

export function useToast(){
    const context = useContext(ToastContext);
   
    return context;
}

export default ToastContext;
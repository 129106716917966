import { useState } from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonSearchbar, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import GaleriaService from '../../services/galeria';
import './Galeria.css';
import { useEffect } from 'react';
import { useToast } from '../../contexts/toast';
import { RouteComponentProps, withRouter } from 'react-router';
import moment from 'moment';

interface GaleriaProps extends RouteComponentProps<{
  unidade: string;
}> { };

const Galeria: React.FC<GaleriaProps> = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [listImagens, setListImagens] = useState([]);
  const [unidade,setUnidade] = useState("");
  const { alert } = useToast();

  useEffect(() => {
    loadGaleria();
  }, [])

  const loadGaleria = async () => {
    try {
      const {data, unidade} = await GaleriaService.get(match.params.unidade);

      setListImagens(data);
      setUnidade(unidade);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert((err as Error).message);
    }
  }

  const _handlerRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    try {
      loadGaleria();
      event.detail.complete();
    } catch (err) {
      event.detail.complete();
    }
  }


  const getList = () => {
    return listImagens.map((image: any, index) => {
      return (
        <IonCard key={index}>
          <IonCardHeader>
            <IonCardSubtitle>
              {image.usuario} - {moment(image.data).format('DD/MM/YYYY HH:mm')}
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <img src={image.link_arquivo} />
          </IonCardContent>
        </IonCard>
      )
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="customdark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            {unidade}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={_handlerRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {loading ?
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <IonSpinner name="crescent" />
          </div>
          : listImagens.length ? getList() :
            <p style={{textAlign: 'center'}}>Galeria sem imagens</p>}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Galeria);

import { RefresherEventDetail } from '@ionic/core';
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonList, IonMenuButton, IonPage, IonPopover, IonRefresher, IonRefresherContent, IonRow, IonSpinner, IonTitle, IonToolbar, useIonPopover } from '@ionic/react';
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Amp from '../../components/gauge/Amp';
import CmdOnOff from '../../components/gauge/cmdOnOff';
import Elevatorio from '../../components/gauge/elevatorio';
import GaugeBase from '../../components/gauge/gaugeBase';
import Nano from '../../components/gauge/Nano';
import Tanque from '../../components/gauge/tanque';
import Volt500 from '../../components/gauge/volt500';
import { useToast } from '../../contexts/toast';
import RelogioService from '../../services/relogios';
import { groupBy } from 'lodash';

import './Relogios.css';
import Reservatorio from '../../components/gauge/reservatorio';

interface RelogiosProps extends RouteComponentProps<{
  unidade: string;
  type: string;
}> { };


const PopoverList: React.FC<{
  onHide: () => void;
  token?: string;
}> = ({ onHide, token }) => (
  <IonList>
    <IonItem button onClick={() => {
      window.open(`https://datalog.issodns.com/?sid=${token}`, "_blank") //to open new page
    }}>Analisador Elétrico</IonItem>
  </IonList>
);


const Relogios: React.FC<RelogiosProps> = ({ match, history }) => {
  const unidade = Number(match.params.unidade);
  const [loading, setLoading] = useState(true);
  const [relogioData, setRelogioData] = useState<any>(null);
  const [present, dismiss] = useIonPopover(PopoverList, { onHide: () => dismiss(), token: relogioData?.unidade_token });
  const { alert } = useToast();

  const [miniIndicator, setMiniIndicator] = useState([]);
  const [listRelogios, SetListRelogios] = useState([]);

  useEffect(() => {
    loadDataRelogio();
  }, [])

  const loadDataRelogio = async () => {
    try {
      const dataRelogio = await RelogioService.getAll(unidade);

      setRelogioData(dataRelogio);

      const miniIndicators = dataRelogio.data.filter((mini: any) => mini.etype == 2 || mini.etype == 4);
      setMiniIndicator(miniIndicators);
      
      const relogios = dataRelogio.data.filter((mini: any) => mini.etype != 2 && mini.etype != 4 && mini.rowapp != 0 && mini.colapp != 0);

      const relogiosGroup = groupBy(relogios,'rowapp');
      
      SetListRelogios(relogiosGroup as any);

      setLoading(false);
    } catch (err) {
      alert((err as Error).message);
    }
  }

  const _handlerRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    try {
      loadDataRelogio();
      event.detail.complete();
    } catch (err) {
      event.detail.complete();
    }
  }

  const _handlerGrafico = async (event: any) => {
    history.push(`/graficos/${unidade}`);
  }

  const getRelogio = (relogio: any) => {
    const valor = Math.round(relogio.valor_atual);
    switch (relogio.tipo) {
      case 1:
        return (
          <Amp value={valor} description={relogio.descricao} maxValue={relogio.maximo} />
        );
      case 2:
        return (
          <Nano value={valor} description={relogio.descricao} maxValue={relogio.maximo} />
        )
      case 3:
        return (
          <Tanque value={valor} description={relogio.descricao}/>
        )
      case 4:
      case 9:
        return (
          <Volt500 value={valor} description={relogio.descricao}/>
        )
      case 10: 
        return (
          <Reservatorio value={valor} description={relogio.descricao} />
        )
      case 21:
        return (
          <Elevatorio value={valor} description={relogio.descricao}/>
        );
      case 24:
        return (
          <CmdOnOff value={valor} description={relogio.descricao}/>
        )
      default:
        return (
          <GaugeBase value={valor} description={relogio.descricao} max={relogio.maximo}/>
        )
    }
  }
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="customdark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            Simos
          </IonTitle>
          {
            relogioData?.unidade_token ? <IonButtons slot="primary">
              <IonButton onClick={(e) => {
                console.log('teste...');
                present({
                  event: e.nativeEvent,
                })
              }
              }>
                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
              </IonButton>
            </IonButtons> : null
          }
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {loading ?
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <IonSpinner name="crescent" />
          </div>
          :
          <>
            <IonToolbar color="customdark">
              <div className="subtitle">
                <div className="item">{relogioData?.unidade_nome}</div>
                <div className="item text-center">{relogioData ? moment(relogioData.date_time).format('DD/MM/YYYY HH:mm') : ''}</div>
                <div className="item text-right">
                  <img className="btn-grafic" src={`${process.env.PUBLIC_URL}/assets/icon/bar-chart.png`} onClick={_handlerGrafico} />
                </div>
              </div>
            </IonToolbar>

            <IonRefresher slot="fixed" onIonRefresh={_handlerRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <div className="mini-info">
              {
                miniIndicator.map((mini: any, index) => (
                  <div className="item" key={index}>
                    <p>{mini.descricao}</p>
                    <p>{Number(mini.leitura).toFixed(1)}</p>
                  </div>
                ))
              }
            </div>
            <IonGrid>
              {
                Object.values(listRelogios)
                  .map((relogios:any,indexRow)=>(
                    <IonRow key={`row_${indexRow}`}>
                      {
                        relogios.map((relogio:any, indexCol:number)=>(
                          <IonCol sizeMd="4" sizeLg="2" key={`col_${indexCol}`} style={{margin:'auto',maxWidth:'200px'}}>
                            {getRelogio(relogio)}
                          </IonCol>
                        ))
                      }
                    </IonRow>
                  ))
              }

              
              {/* <IonRow>
                {
                  listRelogios.map((relogio: any, index) => (
                    <IonCol sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="2" key={index}>
                      {getRelogio(relogio)}
                    </IonCol>
                  ))
                }
              </IonRow> */}
            </IonGrid>
          </>}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Relogios);

import { useEffect, useState } from "react";


export interface CmdOnOffProps {
    value: number,
    description?: string,
}

const CmdOnOff: React.FC<CmdOnOffProps> = (props) => {
    const [anglePoiter, SetAnglePoiter] = useState(0);

    useEffect(() => {
        let angle = props.value == 1 ? 60 : -60;

        SetAnglePoiter(angle);
    }, [props.value])

    return (
        <div>
            <div className="gauge-value">{props.value}</div>
            <svg width={'100%'} viewBox="0 0 300 300">
                <image href={`${process.env.PUBLIC_URL}/assets/icon/cmd-onoff.jpeg`} height={300} width={300} />
                <image
                    href={`${process.env.PUBLIC_URL}/assets/icon/ponteiro-onoff.png`} 
                    height={300} 
                    width={300}
                    transform={`rotate(${anglePoiter} 150 200)`}>
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 150 200" 
                            to={`${anglePoiter} 150 200`} 
                            dur=".5s"
                            fill="freeze" />
                </image>
            </svg>

            <div className="gauge-title">{props.description}</div>
        </div>
    );
};

export default CmdOnOff;

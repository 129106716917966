import React from 'react';
import { FunctionComponent } from 'hoist-non-react-statics/node_modules/@types/react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, LabelProps, LabelList, ResponsiveContainer } from 'recharts';

import './ChartsLine.css';


export interface ChartsLineValue {
  name: string;
  value: number;
}
export interface ChartsLineProps {
  description?:string;
  max?: number;
  min?: number;
  data?: Array<ChartsLineValue>;
  colorLine:string; 
}

const CustomizedLabel: React.FC<LabelProps> = (props) => {
  return (
    <text x={props.x} y={props.y} dy={-4} fill={"#f3f3f3"} fontSize={14} textAnchor="middle">
      {props.value}
    </text>
  )
}

const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        // x={20}
        // y={0}
        // dy={16}
        dy={16}
        fontSize='10'
        textAnchor='middle'
        //textAnchor="end"
        fill="#f3f3f3"
      >
        {payload.value}
      </text>
    </g>
  );
};

const ChartsLine: React.FC<ChartsLineProps> = (props) => {

  return (
    <div className="chart-line">
      <div className="description">{props.description}</div>
      <ResponsiveContainer width={"100%"} aspect={1}>
        <LineChart
          data={props.data}
          margin={{top: 13,right: 20,left: 15}}
        >
          {/* <ReferenceLine x="21:45" stroke="red" label="Max" /> */}
          <ReferenceLine y={props.max || 0} stroke="red"/>
          <ReferenceLine y={props.min || 0} stroke="green"/>

          <CartesianGrid strokeDasharray="2 2"/>
          <XAxis dataKey="name" height={30} tick={<CustomizedAxisTick />} interval={0}/>
          <Line type="monotone" dataKey="value" stroke={props.colorLine || "#f3f3f3"}>
            <LabelList content={<CustomizedLabel />} />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartsLine;

import { IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';
import { menuController } from '@ionic/core';
import { RouteComponentProps, withRouter } from 'react-router';

interface HomeProps extends RouteComponentProps<any> { };

const Home: React.FC<HomeProps> = ({ history }) => {

  const _handlerOpenUnidades = (tipo:string) => {
    history.push(`/unidades/${tipo}`);
  }

  const _handlerOpenNotificacoes = (e: any) => {
    history.push('/notificacoes');
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="customdark">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            Simos
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div style={{
          height: '100%',
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/image/background.png)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}>
          <div className="home-buttons">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <div className="img-logo"><img src={`${process.env.PUBLIC_URL}/assets/image/logo.png`} /></div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6"><div className="button left" onClick={() => _handlerOpenUnidades('relogios')}><img src={`${process.env.PUBLIC_URL}/assets/image/mainmenu_medidor.png`} /></div></IonCol>
                <IonCol size="6"><div className="button right" onClick={() => _handlerOpenUnidades('graficos')}><img src={`${process.env.PUBLIC_URL}/assets/image/mainmenu_grafico.png`} /></div></IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6"><div className="button left" onClick={() => _handlerOpenUnidades('galeria')}><img src={`${process.env.PUBLIC_URL}/assets/image/mainmenu_galeria.png`} /></div></IonCol>
                <IonCol size="6"><div className="button right" onClick={_handlerOpenNotificacoes}><img src={`${process.env.PUBLIC_URL}/assets/image/mainmenu_notificacao.png`}/></div></IonCol>
              </IonRow>
            </IonGrid>
          </div>


          {/* <div className="home-footer-logo">
            <img className="logo" src={`${process.env.PUBLIC_URL}/assets/image/logo_small.png`} />
          </div> */}
        </div>

      </IonContent>
    </IonPage>
  );
};

export default withRouter(Home);

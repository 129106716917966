import { useEffect, useState } from "react";


export interface Volt500Props {
    value: Number,
    description?: string
}

const Volt500: React.FC<Volt500Props> = (props) => {
    const [anglePoiter, SetAnglePoiter] = useState(0);

    useEffect(() => {
        const value = (props.value as number);
        let angle = 0;

        if(value <= 100){
            angle = ((6 / 100) * value);
        }else if(value <= 200) {
            angle = ((22 / 200) * value);
        }else if(value <= 300) {
            angle = ((43 / 300) * value);
        }else if(value <= 400) {
            angle = ((65 / 400) * value);
        }else{
            angle = ((82 / 500) * value);
        }
        const pointer = (angle + 180);
        SetAnglePoiter(pointer > 280 ? 280 : pointer);
    }, [props.value])


    return (
        <div>
            <div className="gauge-value">{props.value}V</div>
            <svg width={'100%'} viewBox="0 0 300 300">
                <image href={`${process.env.PUBLIC_URL}/assets/icon/basevoltimetro.png`} height={300} width={300} />                  
                <defs>
                <g id="arrow">
                    <g transform="translate(236 210)">
                        <path id="point" 
                            d="M0,0H162l4.982,3.461L162,6.5H0S-6.484,5-6.484,3.377,0,0,0,0Z"
                            transform="rotate(180 2 2)"
                            fill="#d34a4a">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="180 2 2" 
                                to={`${anglePoiter} 2 2`} 
                                dur=".5s"
                                fill="freeze">
                                </animateTransform>
                        </path>
                    </g>
                    <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(219,190)" fill="#5c5757" stroke="#433f3f" strokeWidth={8}>
                        <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
                        <circle cx="20.5" cy="20.5" r="16.5" fill="none" />
                    </g>
                </g>
                </defs>
                <use xlinkHref="#arrow" />
            </svg>

            <div className="gauge-title">{props.description}</div>
        </div>
    );
};

export default Volt500;

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import Routes from './routes';
import { AuthProvider } from './contexts/auth';
import { ToastProvider } from './contexts/toast';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';

const App: React.FC = () => {
  useEffect(()=>{
    const isAvailable = Capacitor.isPluginAvailable('StatusBar');
    if(isAvailable)
    {
      setStatusBarStyleDark();
    }
  },[]);

  const setStatusBarStyleDark = async () => {
    await StatusBar.setStyle({ style: Style.Dark });
  };

  return (
    <AuthProvider>
      <ToastProvider>
        <Routes />
      </ToastProvider>
    </AuthProvider>
  )
};

export default App;

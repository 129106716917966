import { IonAlert, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import {
    Formik,
    FormikProps,
    Form,
    FormikHelpers,
} from 'formik';
import { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ButtonLogin from '../../components/buttonLogin/ButtonLogin';
import InputLogin from '../../components/inputLogin/InputLogin';
import { useAuth } from '../../contexts/auth';
import AuthService from '../../services/auth';
import { getTokenAccess } from '../../utils/auth';

import './Login.css';

interface LoginProps extends RouteComponentProps<any> { };

interface LoginFormValues {
    login: string;
    senha: string;
}

const validate = (values: LoginFormValues) => {
    let errors: any = {};
    if (values.login === "") {
        errors.login = "Campo obrigatório";
    }

    if (values.senha === "") {
        errors.senha = "Campo obrigatório";
    }

    return errors;

}

const Login: React.FC<LoginProps> = ({ history }) => {
    const { login,logout } = useAuth();
    const [loading,setLoading] = useState(false);

    const initialValues: LoginFormValues = { login: '', senha: '' };
    const [isError, SetIsError] = useState('');

    useEffect(()=>{
        const tokenAccess =  getTokenAccess();
        if(!tokenAccess){
            logout();
        }
    },[])


    const _handlerSubmit = async(values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
        try{
            setLoading(true);
            const usuario = await AuthService.login({
                login: values.login,
                senha: values.senha
            });

            login(usuario);

            history.push('/');
            setLoading(false);
        }catch(err){
            SetIsError((err as Error).message);
            setLoading(false);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="customdark">
                    <IonTitle>Simos</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{
                    minHeight: '100%',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/image/background.png)`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={_handlerSubmit}
                    >

                        {(props: FormikProps<LoginFormValues>) => (

                            <Form>
                                <div className="group-input">
                                    <img className="logo" src={`${process.env.PUBLIC_URL}/assets/image/logo.png`} />
                                    <div className="input">
                                        <InputLogin
                                            name="login"
                                            type="text"
                                            icon={`${process.env.PUBLIC_URL}/assets/image/login_user.png`}
                                            placeholder='Usuário' />
                                    </div>
                                    <div className="input">
                                        <InputLogin
                                            name="senha"
                                            type="password"
                                            icon={`${process.env.PUBLIC_URL}/assets/image/login_pass.png`}
                                            placeholder='Senha' />
                                    </div>
                                    <ButtonLogin 
                                        icon={`${process.env.PUBLIC_URL}/assets/image/login_button.png`} 
                                        isLoading={loading}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>

                    <div className='clearfix'/>
                </div>
                <IonAlert
                    isOpen={!!isError}
                    onDidDismiss={() => SetIsError('')}
                    cssClass="my-custom-class"
                    header={'Ops.'}
                    message={isError}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    );
};

export default withRouter(Login);

import { RefresherEventDetail } from '@ionic/core';
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Amp from '../../components/gauge/Amp';
import Elevatorio from '../../components/gauge/elevatorio';
import Nano from '../../components/gauge/Nano';
import Tanque from '../../components/gauge/tanque';
import Volt500 from '../../components/gauge/volt500';
import { useToast } from '../../contexts/toast';

import RelogioService from '../../services/relogios';
import './RelogiosMulti.css';

interface RelogiosMultiProps extends RouteComponentProps<{
  id: string;
}> { };

const RelogiosMulti: React.FC<RelogiosMultiProps> = ({ match, location }) => {
  const [loading, setLoading] = useState(true);
  const [relogioData, setRelogioData] = useState<any>(null);
  const { alert } = useToast();
  const [listRelogios, SetListRelogios] = useState([]);

  useEffect(() => {


    loadDataRelogio();
  }, [])

  const loadDataRelogio = async () => {
    try {
      const dataRelogio = await RelogioService.getAllMulti(match.params.id);
      setRelogioData(dataRelogio);

      SetListRelogios(dataRelogio.data);

      setLoading(false);
    } catch (err) {
      alert((err as Error).message);
    }
  }

  const _handlerRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    try {
      loadDataRelogio();
      event.detail.complete();
    } catch (err) {
      event.detail.complete();
    }
  }

  const getRelogio = (relogio: any) => {
    const valor = Math.round(relogio.valor_atual);

    switch (relogio.tipo) {
      case 1:
        return (
          <Amp value={valor} description={`${relogio.unidade_nome} [${relogio.descricao}]`} maxValue={relogio.maximo}></Amp>
        );
      case 2:
        return (
          <Nano value={valor} description={`${relogio.unidade_nome} [${relogio.descricao}]`} maxValue={relogio.maximo} />
        )
      case 3:
        return (
          <Tanque value={valor} description={`${relogio.unidade_nome} [${relogio.descricao}]`}></Tanque>
        )
      case 4:
      case 9:
        return (
          <Volt500 value={valor} description={`${relogio.unidade_nome} [${relogio.descricao}]`}></Volt500>
        )
      case 21:
        return (
          <Elevatorio value={valor} description={`${relogio.unidade_nome} [${relogio.descricao}]`}></Elevatorio>
        );
      default:
        break;
    }
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="customdark">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            Simos
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {loading ?
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <IonSpinner name="crescent" />
          </div>
          :
          <>
            <IonToolbar color="customdark">
              <div className="subtitle">
                <div className="item">{relogioData?.nome}</div>
                {/* <div className="item text-center"></div> */}
                {/* <div className="item text-right">
                  <img className="btn-grafic" src={`${process.env.PUBLIC_URL}/assets/icon/bar-chart.png`} />
                </div> */}
              </div>
            </IonToolbar>
            <IonRefresher slot="fixed" onIonRefresh={_handlerRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <IonGrid>
              <IonRow>
                {
                  listRelogios.map((relogio: any, index) => (
                    <IonCol sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="2" key={index}>
                      {getRelogio(relogio)}
                    </IonCol>
                  ))
                }
              </IonRow>
            </IonGrid>
          </>}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(RelogiosMulti);
